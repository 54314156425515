import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import { decodeText } from '../utilities/helpers'
import { Link } from "gatsby"
import LayoutContent from '../components/layout/layoutContent'

const { getLocaleName } = require('../utilities/locale')

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: '4em',
    },
    container: {
        paddingTop: '2em'
    },
    link: {
        border: 'none',
        fontWeight: 'normal',
        textTransform: 'initial',
        fontStretch: 'initial',
        alignSelf: 'flex-start',
        lineHeight: 1.66,
        fontSize: '1em'
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '2em'
    }
}))

const Locales = props => {
    const { state } = props.location
    const prevPath = !!state && !!state.prevPath ? state.prevPath : ''
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <LayoutContent {...props} setBackgroundImage>
            <Helmet title={`${props.data.siteData.siteMetadata.title} | ${t('Locales')}`} />
            <div className={classes.root}>
                <Typography align='center' variant='h1' className='h2'>{t('This site in other countries/regions')}</Typography>
                <Grid container className={classes.container}>
                    <Grid item xs={12} md={6} lg={3} className={classes.column}>
                        <Typography variant='h2' className='h5'>{t('America')}</Typography>

                        <Link color='primary' className={classes.link} to={`/${'pt-br'}${prevPath}`} title={getLocaleName('pt-br')} isFullUrl={true} target='_self'>
                            {getLocaleName('pt-br')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'en-ca'}${prevPath}`} title={getLocaleName('en-ca')} isFullUrl={true} target='_self'>
                            {getLocaleName('en-ca')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'fr-ca'}${prevPath}`} title={getLocaleName('fr-ca')} isFullUrl={true} target='_self'>
                            {getLocaleName('fr-ca')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'es-cl'}${prevPath}`} title={getLocaleName('es-cl')} isFullUrl={true} target='_self'>
                            {getLocaleName('es-cl')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'es-co'}${prevPath}`} title={getLocaleName('es-co')} isFullUrl={true} target='_self'>
                            {getLocaleName('es-co')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'es-mx'}${prevPath}`} title={getLocaleName('es-mx')} isFullUrl={true} target='_self'>
                            {getLocaleName('es-mx')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'en-us'}${prevPath}`} title={getLocaleName('en-us')} isFullUrl={true} target='_self'>
                            {getLocaleName('en-us')}
                        </Link>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3} className={classes.column}>
                        <Typography variant='h2' className='h5'>{t('Europe')}</Typography>

                        <Link color='primary' className={classes.link} to={`/${'fr-be'}${prevPath}`} title={getLocaleName('fr-be')} isFullUrl={true} target='_self'>
                            {getLocaleName('fr-be')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'de-de'}${prevPath}`} title={getLocaleName('de-de')} isFullUrl={true} target='_self'>
                            {getLocaleName('de-de')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'es-es'}${prevPath}`} title={getLocaleName('es-es')} isFullUrl={true} target='_self'>
                            {getLocaleName('es-es')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'fr-fr'}${prevPath}`} title={getLocaleName('fr-fr')} isFullUrl={true} target='_self'>
                            {getLocaleName('fr-fr')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'en-ie'}${prevPath}`} title={getLocaleName('en-ie')} isFullUrl={true} target='_self'>
                            {getLocaleName('en-ie')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'it-it'}${prevPath}`} title={getLocaleName('it-it')} isFullUrl={true} target='_self'>
                            {getLocaleName('it-it')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'pl-pl'}${prevPath}`} title={getLocaleName('pl-pl')} isFullUrl={true} target='_self'>
                            {getLocaleName('pl-pl')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'fr-ch'}${prevPath}`} title={getLocaleName('fr-ch')} isFullUrl={true} target='_self'>
                            {getLocaleName('fr-ch')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'en-gb'}${prevPath}`} title={getLocaleName('en-gb')} isFullUrl={true} target='_self'>
                            {getLocaleName('en-gb')}
                        </Link>

                        <Link lang='ru-ru' color='primary' className={classes.link} to={`/${'ru-ru'}${prevPath}`} title={getLocaleName('ru-ru')} isFullUrl={true} target='_self'>
                            {getLocaleName('ru-ru')}
                        </Link>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3} className={classes.column}>
                        <Typography variant='h2' className='h5'>{decodeText(t('slug:asia-pacific'))}</Typography>

                        <Link color='primary' className={classes.link} to={`/${'en-au'}${prevPath}`} title={getLocaleName('en-au')} isFullUrl={true} target='_self'>
                            {getLocaleName('en-au')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'en-in'}${prevPath}`} title={getLocaleName('en-in')} isFullUrl={true} target='_self'>
                            {getLocaleName('en-in')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'en-nz'}${prevPath}`} title={getLocaleName('en-nz')} isFullUrl={true} target='_self'>
                            {getLocaleName('en-nz')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'en-sg'}${prevPath}`} title={getLocaleName('en-sg')} isFullUrl={true} target='_self'>
                            {getLocaleName('en-sg')}
                        </Link>

                        <Link lang='ko-kr' color='primary' className={classes.link} to={`/${'ko-kr'}${prevPath}`} title={getLocaleName('ko-kr')} isFullUrl={true} target='_self'>
                            {getLocaleName('ko-kr')}
                        </Link>

                        <Link lang='zh-cn' color='primary' className={classes.link} to={`/${'zh-cn'}${prevPath}`} title={getLocaleName('zh-cn')} isFullUrl={true} target='_self'>
                            {getLocaleName('zh-cn')}
                        </Link>

                        <Link lang='zh-tw' color='primary' className={classes.link} to={`/${'zh-tw'}${prevPath}`} title={getLocaleName('zh-tw')} isFullUrl={true} target='_self'>
                            {getLocaleName('zh-tw')}
                        </Link>

                        <Link lang='ja-jp' color='primary' className={classes.link} to={`/${'ja-jp'}${prevPath}`} title={getLocaleName('ja-jp')} isFullUrl={true} target='_self'>
                            {getLocaleName('ja-jp')}
                        </Link>
                    </Grid>

                    <Grid item xs={12} md={6} lg={3} className={classes.column}>
                    <Typography variant='h2' className='h5'>{decodeText(t('slug:middle-east-africa'))}</Typography>

                        <Link color='primary' className={classes.link} to={`/${'en-za'}${prevPath}`} title={getLocaleName('en-za')} isFullUrl={true} target='_self'>
                            {getLocaleName('en-za')}
                        </Link>

                        <Link color='primary' className={classes.link} to={`/${'tr-tr'}${prevPath}`} title={getLocaleName('tr-tr')} isFullUrl={true} target='_self'>
                            {getLocaleName('tr-tr')}
                        </Link>

                        <Link lang='ar-ae' color='primary' className={classes.link} to={`/${'ar-ae'}${prevPath}`} title={getLocaleName('ar-ae')} isFullUrl={true} target='_self'>
                            {getLocaleName('ar-ae')}
                        </Link>

                        <Link lang='ar-sa' color='primary' className={classes.link} to={`/${'ar-sa'}${prevPath}`} title={getLocaleName('ar-sa')} isFullUrl={true} target='_self'>
                            {getLocaleName('ar-sa')}
                        </Link>
                    </Grid>
                </Grid>
            </div>
        </LayoutContent>
    )
}

export const pageQuery = graphql`
    query LocalesQuery {
        siteData: site {
            siteMetadata {
                title
            }
        }
    }
`

export default Locales
